<template>
  <div class="container">
    <div class="swiper-img">
      <van-swipe @change="onChange" :stop-propagation="false">
        <van-swipe-item v-for="(item, index) in imgList" :key="index">
          <div class="img">
            <!-- <img :src="item" alt @click="lookAll" /> -->
            <van-image fit="cover" :src="item.value" @click="lookAlls(0, 0)" />
            <!-- <van-image  fit="scale-down" :src="item" width="100vw" height="211px" /> -->
            <!-- <van-image  fit="none" :src="item" width="100vw" height="211px" /> -->
            <!-- <van-image  fit="cover" :src="item" width="100vw" height="211px" /> -->
          </div>
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator" style="color: #ffffff">
            {{ current + 1 }}/{{ imgList.length }}
          </div>
        </template>
      </van-swipe>
    </div>
    <div class="main">
      <!-- 价格 -->
      <div class="title">
        <ul class="title-n">
          <li>
            <div>
              <span class="m-tip">￥</span>
              <span class="m-num">{{ titleInfo.price }}</span>
              <span class="m-let">万元</span>
            </div>
            <div class="reset">建议零售价</div>
          </li>
          <li class="timer">
            <div class="timer-t">
              {{ titleInfo.certificationTime | dateFormatMonthsText }}
            </div>
            <div class="day">
              <div>认证日期</div>
              <!-- <img src="../../assets/report/crazy.png" alt /> -->
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="CertificationBody">123</div> -->
      <!-- 车辆标题 -->
      <div class="carmsg">
        <div class="carName">{{ titleInfo.carName }}</div>
        <!-- <div class="series" @click="showSeries = true">
          <div>调整系数</div>
          <img src="../../assets/report/crazy.png" alt />
        </div> -->
        <!-- <ul class="changeNumber">
          <li>
            <div>{{ titleInfo.marketForce }}</div>
            <div>区域市场影响</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{ titleInfo.brandForce }}</div>
            <div>品牌影响</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{ titleInfo.usedForce }}</div>
            <div>使用状况影响</div>
          </li>
        </ul> -->
      </div>

      <!-- 特殊事项说明 -->
      <div class="specialTip">
        <div class="carImgTitle c-p">特殊事项说明</div>
        <div class="sp-tips" v-if="titleInfo.specialDesc">
          {{ titleInfo.specialDesc }}
        </div>
        <div class="sp-tips" v-if="!titleInfo.specialDesc">-</div>
      </div>

      <ul class="newList">
        <li @click="showHistory = true">
          <div>{{ titleInfo.newYearsRate | formatKoreanNumber }}%</div>
          <div><span>年限成新率</span></div>
        </li>
        <li>
          <div>{{ titleInfo.mileageRate | formatKoreanNumber }}%</div>
          <div>里程成新率</div>
        </li>
        <li>
          <div>{{ titleInfo.techRate | formatKoreanNumber }}%</div>
          <div>技术成新率</div>
        </li>
        <li>
          <div>{{ titleInfo.impairment | formatKorean }}</div>
          <div>实体性减值(元)</div>
        </li>
      </ul>

      <div class="CertificationBody">
        <div class="CertificationBodyImage">
          <div>{{ titleInfo.signatureCn }}</div>
          <div style="margin-top: 4px">二手商用车认证证书</div>
        </div>
        <div class="CertificationBodyBottom">
          <div class="bodyTitle">认证机构：</div>
          <img class="bodyImage" :src="titleInfo.orgChart" alt="" />
          <div class="bodyContent">{{ titleInfo.orgName }}</div>
        </div>
      </div>
      <!-- 评估机构 -->
      <!-- <div class="mechanism">
        <div class="m-title">
          <div>{{ titleInfo.evaOrg }}</div>
          <div>
            评报字(
            <span v-if="titleInfo.year">{{ titleInfo.year }}</span>
            <span v-if="!titleInfo.year">-</span>年 )第
            <span v-if="titleInfo.num">{{ titleInfo.num }}</span>
            <span v-if="!titleInfo.num">-</span> 号
          </div>
        </div>
        内容 
        <ul class="m-content">
          <li>
            <div class="m-tips">
              <div>委</div>
              <div>托</div>
              <div>方 :</div>
            </div>
            <div>{{ titleInfo.clientName }}</div>
          </li>
          <li>
            <div>评估目的 :</div>
            <div>{{ titleInfo.aim }}</div>
          </li>
        </ul>
        <div class="m-before" v-if="mechanism">绪言：</div>
        <div class="m-be-c" v-if="mechanism">
          <span>{{ titleInfo.evaOrg }}</span>
          接受客户委托，根据《中华人民共和国资产评估法》、《二手车流通管理办法》和《商用车鉴定评估技术规范》的规定，本着客观、独立、公正、科学的原则，对牌号为
          <span>{{ titleInfo.plateNo }}</span
          >的车辆进行了鉴定。本机构鉴定评估人员按照必要的程序，对委托鉴定评估的车辆进行了实地查勘与市场调查，并对其在
          <span>{{ titleInfo.evaTime | dateFormatMonth }}</span
          >所表现的市场价值作出了公允反映。
        </div>
        <div class="m-before" v-if="mechanism">声明：</div>
        <ul class="m-be-tip" v-if="mechanism">
          <li>（1）本鉴定评估机构对该鉴定评估报告承担法律责任；</li>
          <li>（2）本报告所提供的车辆评估价值为评估结论有效期内的价值；</li>
          <li>
            （3）该鉴定评估报告的使用权归委托方所有，其鉴定评估结论仅供委托方为本项目鉴定评估目的使用，不适用于其他目的，否则本鉴定评估机构不承担相应法律责任；因使用本报告不当而产生的任何后果与签署本报告书的鉴定评估人员无关；
          </li>
          <li>
            （4）本鉴定评估机构承诺，未经委托方许可，不将本报告的内容向他人提供或公开，否则本鉴定评估机构将承担相应法律责任。
          </li>
          <li>
            委托方承诺所提供车辆及手续均为真实合法，本机构对车辆及相关手续的来源真实性和合法性不承担法律责任。
          </li>
        </ul>
        <div class="showMechanism" v-if="!mechanism" @click="mechanism = true">
          <span>查看更多</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="showMechanism" v-if="mechanism" @click="mechanism = false">
          <span>收起</span>
          <van-icon name="arrow-up" />
        </div>
      </div> -->
      <!-- 评估师 -->
      <div class="appraiser" @click="jumpEav">
        <div class="appraiserTitle">认证员：</div>
        <div class="logo">
          <!-- <div class="a-img"> -->
          <!-- <img :src="titleInfo.avatarUrl" alt /> -->
          <van-image fit="cover" :src="titleInfo.avatarUrl" />
          <!-- </div> -->
          <div class="a-name">{{ titleInfo.name }}</div>
        </div>
        <div class="a-title-img">
          <div class="a-title">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div
              class="a-name"
              style="
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >
              {{ titleInfo.assessor }} {{ titleInfo.levelDesc }}
            </div>
          </div>
          <img class="a-link" src="../../assets/report/arrow.png" alt />
          <!-- <div class="a-title" v-if="titleInfo.levelId == 1">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div class="a-name">商用车鉴定评估师 初级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 2">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div class="a-name">商用车鉴定评估师 中级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 3">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div class="a-name">商用车鉴定评估师 高级</div>
          </div> -->
          <!-- <img class="a-link" src="../../assets/report/arrow.png" alt /> -->
        </div>
      </div>
      <!-- 评估内容 -->
      <div class="carImgTitle">车辆信息</div>
      <!-- <ul class="changeNumber car-name">
        <li>
          <div>{{ msgInfo.carAge }}</div>
          <div>车龄</div>
        </li>
        <li class="lines"></li>
        <li>
          <div>{{ msgInfo.fuelType }}</div>
          <div>燃料</div>
        </li>
        <li class="lines"></li>
        <li>
          <div>{{ msgInfo.emissionStd }}</div>
          <div>排放</div>
        </li>
      </ul> -->
      <div class="appInfo">
        <ul class="app-list">
          <li class="app-item">
            <div>品牌</div>
            <div v-if="msgInfo.brand">{{ msgInfo.brand }}</div>
            <div v-if="!msgInfo.brand">-</div>
          </li>
          <li class="app-item">
            <div>型号</div>
            <div v-if="msgInfo.vehicleModel">{{ msgInfo.vehicleModel }}</div>
            <div v-if="!msgInfo.vehicleModel">-</div>
          </li>
          <li class="app-item">
            <div>发动机号码/电池组编号</div>
            <!-- <div style='width:80px;line-height:14px;'>发动机号码/电池组编号</div> -->
            <div v-if="msgInfo.engineNo">{{ msgInfo.engineNo }}</div>
            <div v-if="!msgInfo.engineNo">-</div>
          </li>
          <li class="app-item">
            <div>发动机型号</div>
            <div v-if="msgInfo.engineModel">{{ msgInfo.engineModel }}</div>
            <div v-if="!msgInfo.engineModel">-</div>
          </li>
          <li class="app-item">
            <div>VIN码</div>
            <div v-if="msgInfo.vin">{{ msgInfo.vin }}</div>
            <div v-if="!msgInfo.vin">-</div>
          </li>
          <li class="app-item">
            <div>表征里程(km)</div>
            <div v-if="msgInfo.mileage">{{ msgInfo.mileage }}</div>
            <div v-if="!msgInfo.mileage">-</div>
          </li>
          <li class="app-item">
            <div>推定里程(km)</div>
            <div class="app-item-img">
              <div v-if="msgInfo.calculationMileage">
                {{ formatThousand(msgInfo.calculationMileage) }}
              </div>
              <div v-if="!msgInfo.calculationMileage">-</div>
              <div v-if="msgInfo.calculationMileage && msgInfo.mileage">
                <img
                  v-if="msgInfo.calculationMileage !== msgInfo.mileage"
                  src="../../assets/report/crazy.png"
                  alt
                  @click="numTime = true"
                />
              </div>
            </div>
          </li>
          <li class="app-item">
            <div>注册登记日期</div>
            <div v-if="msgInfo.registerDate">
              {{ msgInfo.registerDate | dateFormatMonthsText }}
            </div>
            <div v-if="!msgInfo.registerDate">-</div>
          </li>
          <li class="app-item">
            <div>年审检查日期</div>
            <div v-if="msgInfo.yearlyInspectionDue">
              {{ msgInfo.yearlyInspectionDue | dateFormatMonthsText }}
            </div>
            <div v-if="!msgInfo.yearlyInspectionDue">-</div>
          </li>
          <li class="app-item">
            <div>交强险截止日期</div>
            <div v-if="msgInfo.compulsoryTrafficInsuranceDue">
              {{ msgInfo.compulsoryTrafficInsuranceDue | dateFormatMonthsText }}
            </div>
            <div v-if="!msgInfo.compulsoryTrafficInsuranceDue">-</div>
          </li>
          <li class="app-item">
            <div>车船税截止日期</div>
            <div v-if="msgInfo.vehicleVesselTaxDue">
              {{ msgInfo.vehicleVesselTaxDue | dateFormatMonthsText }}
            </div>
            <div v-if="!msgInfo.vehicleVesselTaxDue">-</div>
          </li>
          <li class="app-item app-time">
            <div>第三方责任险截止日期</div>
            <div v-if="msgInfo.thirdInsuranceDue">
              {{ msgInfo.thirdInsuranceDue | dateFormatMonthsText }}
            </div>
            <div v-if="!msgInfo.thirdInsuranceDue">-</div>
          </li>
          <li class="app-item app-time">
            <div>未接受处理的交通违法记录(条)</div>
            <div v-if="msgInfo.violation">
              {{ msgInfo.violation | dateFormatMonthsText }}
            </div>
            <div v-if="!msgInfo.violation">-</div>
          </li>
          <li class="app-item app-time">
            <div>其他重要配置</div>
          </li>
          <li class="app-tip" v-if="msgInfo.otherConfig">
            {{ msgInfo.otherConfig }}
          </li>
        </ul>
        <div class="lookMsg" @click="jumpInfo">查看更多基本信息</div>
      </div>

      <!-- <div class="Usage">
        <div class="u-t">年限使用状况</div>
        <div class="u-li">
          <span>年限折旧标准</span>
          <span v-if="titleInfo.method == 1">情况①</span>
          <span v-if="titleInfo.method == 2">情况②</span>
          <span v-if="titleInfo.method == 3">情况③</span>
          <span v-if="titleInfo.method == 4">情况④</span>
        </div>
        <div class="u-li">
          <span>累计使用年月</span>
          <span>{{ titleInfo.carAge }}</span>
        </div>
      </div>
      <div class="Usage">
        <div class="u-t">行驶里程状况</div>
        <div class="u-li">
          <span>表征里程(km)</span>
          <span>{{ formatThousand(titleInfo.mileage) }}</span>
        </div>
        <div class="u-li">
          <span>推定里程(km)</span>
          <span>{{ formatThousand(titleInfo.calculateMileage) }}</span>
        </div>
        <div class="u-li" v-if="titleInfo.diffReason">
          <span>差异原因</span>
          <span id="u-l-s">{{ titleInfo.diffReason }}</span>
        </div>
      </div> -->

      <!-- 车辆报告 -->
      <div class="car-report">
        <!-- <div class="carImgTitle c-p">车辆技术鉴定报告</div> -->
        <div class="carImgTitle c-p">认证过程</div>
        <!-- <ul class="changeNumber car-name c-p-n">
          <li>
            <div>{{ tips.score  | formatKorean }}</div>
            <div>综合评价分值</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{ tips.level }}</div>
            <div>技术状态等级</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{ tips.techRate  | formatKorean }}</div>
            <div>技术成新率</div>
          </li>
        </ul> -->

        <ul class="report-list">
          <li
            @click="jumpReport(index)"
            v-for="(item, index) in reportInfo"
            :key="index"
          >
            <div class="report-names">
              <!--  -->
              <div style="flex: 1">
                {{ item.name }}({{ item.children.length }}项)
              </div>
              <div class="report-text">{{ item.defectNum }}处</div>
              <van-icon name="arrow" />
            </div>
            <!-- <div class="report-cs">
              <div>满分：{{ item.score || 0 }}</div>
              <div>评价等级：{{ item.level }}</div>
              <div v-if="item.name == '骨架检查项目'">骨架系数：{{ item.score }}</div>
              <div v-else>评估得分：{{ item.score }}</div>
            </div> -->
            <!-- <div class="sub-d">{{ item.statistic }}</div> -->
            <!-- <div v-if="item.defectNum" class="sub-d style_text">{{item.defectNum}}处缺陷，扣{{item.subScore}}分，减值{{item.impairment}}元</div>
            <div v-else class="sub-d style_text" style="color: #C9C9C9;">0处缺陷，扣0分，减值0元</div> -->
            <div class="style_solid"></div>
          </li>
        </ul>
        <!-- <ul class="report-list">
          <li class="report-list-item">
            <div class="report-name">鉴定项目</div>
            <div class="report-number">得分/满分</div>
            <div class="report-level">等级</div>
            <div class="report-bug">缺陷</div>
          </li>
          <li
            @click="jumpReport(index)"
            class="report-list-item"
            v-for="(item, index) in reportInfo.checkItems"
            :key="index"
          >
            <div class="report-name">{{ item.shortName }}</div>
            <div class="report-number" v-if="item.score">
              {{ item.score }}/{{ item.total }}
            </div>
            <div class="report-number" v-if="item.coefficient">
              {{ item.coefficient }}/{{ item.total }}
            </div>
            <div class="report-number" v-if="item.total == 0">
              {{ item.score }}/{{ item.total }}
            </div>
            <div class="report-level">{{ item.level }}</div>
            <div class="report-bug">
              <div v-if="item.impairment" class="error">
                {{ item.impairment }}
              </div>
              <div v-if="!item.impairment" class="success">
                {{ item.impairment }}
              </div>
              <van-icon name="arrow" />
            </div>
          </li>
        </ul> -->
        <div class="lookMsg look-report" @click="jumpReport('无')">
          查看详细报告
        </div>
      </div>
    </div>

    <!-- 车辆图片 -->
    <div class="carImg">
      <div class="carImgTitle">车辆相关图片</div>
      <div class="cag-c">
        <div class="imgSwiper" v-for="(item, index) in list" :key="index">
          <ul class="carimg-nav" @click="lookAlls(index, 0)">
            <li>{{ item.title }}</li>
            <li class="ca-more">
              查看更多
              <van-icon name="arrow" />
            </li>
          </ul>
          <ul ref="imgList" class="carI-list">
            <li v-for="(ite, ind) in item.imgList" :key="ind">
              <!-- <img :src="ite" alt @click="lookAlls(index,ind)" /> -->
              <van-image
                fit="cover"
                :src="ite.value"
                @click="lookAlls(index, ind)"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 实体减值项描述 -->
    <!-- <div class="impairmentItem">
      <div class="carImgTitle c-p">实体减值项描述</div>
      <div class="im-nav">
        <div class="im-item im-items">
          <div>1.{{ impairment[0].title }}</div>
          <div>
            {{ impairment[0].count }}处缺陷,小计减值￥{{
              formatCurrency(impairment[0].totalMoney)
            }}
          </div>
        </div>
      </div>
      <ul @click="jumpImpairment" v-if="impairment[0].children">
        <li v-for="(item, index) in impairment[0].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{ index + 1 }}) 减值项</div>
            <div>{{ item.label }}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{ item.desc }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{ formatCurrency(item.money) }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div
                class="im-img-item"
                v-for="(ite, ind) in item.images"
                :key="ind"
              >
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="im-nav">
        <div class="im-item">
          <div>2.{{ impairment[1].title }}</div>
          <div>
            {{ impairment[1].count }}处缺陷,小计减值￥{{
              formatCurrency(impairment[1].totalMoney)
            }}
          </div>
        </div>
      </div>
      <ul @click="jumpImpairment" v-if="impairment[1].children">
        <li v-for="(item, index) in impairment[1].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{ index + 1 }}) 减值项</div>
            <div>{{ item.label }}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{ item.desc }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{ formatCurrency(item.money) }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div
                class="im-img-item"
                v-for="(ite, ind) in item.images"
                :key="ind"
              >
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="im-nav">
        <div class="im-item">
          <div>3.{{ impairment[2].title }}</div>
          <div>
            {{ impairment[2].count }}处缺陷,小计减值￥{{
              formatCurrency(impairment[2].totalMoney)
            }}
          </div>
        </div>
      </div>
      <ul @click="jumpImpairment" v-if="impairment[2].children">
        <li v-for="(item, index) in impairment[2].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{ index + 1 }}) 减值项</div>
            <div>{{ item.label }}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{ item.desc }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{ formatCurrency(item.money) }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div
                class="im-img-item"
                v-for="(ite, ind) in item.images"
                :key="ind"
              >
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="im-nav">
        <div class="im-item">
          <div>4.{{ impairment[3].title }}</div>
          <div>
            {{ impairment[3].count }}处缺陷,小计减值￥{{
              formatCurrency(impairment[3].totalMoney)
            }}
          </div>
        </div>
      </div>
      <ul @click="jumpImpairment" v-if="impairment[3].children">
        <li v-for="(item, index) in impairment[3].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{ index + 1 }}) 减值项</div>
            <div>{{ item.label }}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{ item.desc }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{ formatCurrency(item.money) }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div
                class="im-img-item"
                v-for="(ite, ind) in item.images"
                :key="ind"
              >
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="im-nav">
        <div class="im-item">
          <div>5.{{ impairment[4].title }}</div>
          <div>
            {{ impairment[4].count }}处缺陷,小计减值￥{{
              formatCurrency(impairment[4].totalMoney)
            }}
          </div>
        </div>
      </div>
      <ul @click="jumpImpairment" v-if="impairment[4].children">
        <li v-for="(item, index) in impairment[4].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{ index + 1 }}) 减值项</div>
            <div>{{ item.label }}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{ item.desc }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{ formatCurrency(item.money) }}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div
                class="im-img-item"
                v-for="(ite, ind) in item.images"
                :key="ind"
              >
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div
        class="lookMsg look-report"
        v-if="impairmentStatus"
        @click="jumpImpairment"
      >
        查看实体性减值
      </div>
    </div> -->
    <!-- 其他认证资料
    <div class="certificationData">
      <div class="carImgTitle c-p">其他认证资料</div>
      <div class="certificationDataFlex" v-if="titleInfo">
        <div class="certificationDataTitle">保险缴纳记录</div>
        <div class="certificationDataOl" v-if="titleInfo.insPay.length > 0">
          <img
            @click="onImageClick(titleInfo.insPay, index)"
            v-for="(item, index) in titleInfo.insPay"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
        <div v-if="titleInfo.insPay.length == 0" class="certificationDataOlNull">
          暂无资料
        </div>
      </div>
      <div class="solid"></div>
      <div class="certificationDataFlex" v-if="titleInfo">
        <div class="certificationDataTitle">保险理赔记录</div>
        <div class="certificationDataOl" v-if="titleInfo.insClaims.length > 0">
          <img
            @click="onImageClick(titleInfo.insClaims, index)"
            v-for="(item, index) in titleInfo.insClaims"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
        <div v-if="titleInfo.insClaims.length == 0" class="certificationDataOlNull">
          暂无资料
        </div>
      </div>
      <div class="solid"></div>
      <div class="certificationDataFlex" v-if="titleInfo">
        <div class="certificationDataTitle">车辆维修记录</div>
        <div class="certificationDataOl" v-if="titleInfo.repair.length > 0">
          <img
            @click="onImageClick(titleInfo.repair, index)"
            v-for="(item, index) in titleInfo.repair"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
        <div v-if="titleInfo.repair.length == 0" class="certificationDataOlNull">
          暂无资料
        </div>
      </div>
      <div class="solid"></div>
      <div class="certificationDataFlex" v-if="titleInfo">
        <div class="certificationDataTitle">专业设备检测报告</div>
        <div class="certificationDataOl" v-if="titleInfo.detection.length > 0">
          <img
            @click="onImageClick(titleInfo.detection, index)"
            v-for="(item, index) in titleInfo.detection"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
        <div v-if="titleInfo.detection.length == 0" class="certificationDataOlNull">
          暂无资料
        </div>
      </div>
      <div class="solid"></div>
      <div class="certificationDataFlex" v-if="titleInfo">
        <div class="certificationDataTitle">整备项目报告</div>
        <div class="certificationDataOl" v-if="titleInfo.refurbish.length > 0">
          <img
            @click="onImageClick(titleInfo.refurbish, index)"
            v-for="(item, index) in titleInfo.refurbish"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
        <div v-if="titleInfo.refurbish.length == 0" class="certificationDataOlNull">
          暂无资料
        </div>
      </div>
    </div> -->

    <div class="wrapper_box">
      <div class="title_1">承诺质保期</div>
      <div class="text">
        自购车之日起，本车辆以下部件在满足质保条件下本公司实行质量保修。
      </div>
      <div class="text">
        注：对于质保期的时间或里程数均有限定的，以先到的为准。
      </div>
      <div v-if="titleInfo.warranty">
        <div
          v-for="(item, index) in titleInfo.warranty"
          class="wrapper_box_view"
          style="margin-top: 12px"
          :key="index"
        >
          <div class="flex" style="margin-bottom: 6px">
            <div class="title_1">质保项1</div>
            <div class="text_1">{{ item.name }}</div>
          </div>
          <div class="flex" style="margin-bottom: 6px">
            <div class="title_1">条件1：时限</div>
            <div class="text_1">{{ item.value1 }}</div>
          </div>
          <div class="flex" v-if="item.value2">
            <div class="title_1">条件2：里程</div>
            <div class="text_1">{{ item.value2 }}</div>
          </div>
        </div>
      </div>
      <div v-else class="wuz">
        <img src="../../assets/zhengb.png" alt="" />
        <div>无质保项</div>
      </div>
    </div>

    <!-- <div class="wrapper_box">
      <div class="title_1">认证申明</div>
      <div class="text">
        1.经本公司认证的车辆符合国家标准和法规的质量要求，未采取非法改装、非法拼装的手段对车辆进行性能修复。
      </div>
      <div class="text">2.经本公司认证的车辆在认证承诺内承担车辆质量保证。</div>
      <div class="text">
        3.如有任何违反承诺的行为，我公司愿意承担相应法律责任。
      </div>
    </div> -->
    <!-- 弹窗 -->
    <div class="dayDia">
      <van-popup v-model="showTime" closeable>
        <div class="showTimeTitle">评估基准日</div>
        <div class="showTimeTip">
          本鉴定评估结果可以作为报价参考依据。本项鉴定评估结论有效期为30天，自鉴定评估基准日至4月3日止。
        </div>
      </van-popup>
    </div>
    <div class="serDia">
      <van-popup v-model="showSeries" closeable>
        <div class="showTimeTitle">调整系数</div>
        <ul class="showSeries">
          <li class="showSeriesT">区域市场影响：</li>
          <li class="showSeriesTe">
            主要是指车辆在某区域保有量、环保要求、营销宣传、服务保障能力等形成的口碑，对二手商用车成交价格产生影响。
          </li>
          <li class="showSeriesT">品牌影响：</li>
          <li class="showSeriesTe">
            品牌也代表了车辆技术水平、制造水平、服务承诺等，从而影响二手车的保值程度。
          </li>
          <li class="showSeriesT">使用状况影响：</li>
          <li class="showSeriesTe">包括使用状况和使用环境工况。</li>
          <li class="showSeriesD showSeriesTd">
            <span>使用状况：</span>
            <span>里程、营运方式等影响；</span>
          </li>
          <li class="showSeriesD">
            <span>使用工况：</span>
            <span>道路条件、环境、载重等影响。</span>
          </li>
        </ul>
      </van-popup>
    </div>
    <div class="numDia">
      <van-popup v-model="numTime" closeable>
        <div class="showTimeTitle">推定里程</div>
        <div class="showTimeTip Ntips">差异原因人为调表</div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import {
  certificateReport,
  getVehicleImages_,
  reportInfo,
  certificationGetTechAppraisal,
  getImpairment,
} from "@/utils/http.js";
import { ImagePreview } from "vant";

export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      numTime: false,
      // 当前页码
      current: 0,
      // 评估基准日展示
      showTime: false,
      // 车系展示
      showSeries: false,
      imgList: [],
      mechanism: false, //评估机构信息
      list: [],
      titleInfo: "",
      msgInfo: "",
      tips: {},
      reportInfo: "",
      impairment: [
        { title: "驾驶室", count: "0", totalMoney: "0", children: [] },
        { title: "发动机", count: "0", totalMoney: "0", children: [] },
        { title: "底盘", count: "0", totalMoney: "0", children: [] },
        { title: "上装", count: "0", totalMoney: "0", children: [] },
        { title: "其他减值项", count: "0", totalMoney: "0", children: [] },
      ],
      impairmentStatus: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    document.title = "商用车认证资料";
  },
  mounted() {
    this.$nextTick(() => {
      this._loopImg();
    });
    let id = this.$route.query.id;
    // 图片
    // 顶部
    this.getList(id);
    //
  },
  methods: {
    formatThousand(num) {
      if (!num) return "-";
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // 获取上半部分数据
    async getList(id) {
      const { data: res } = await certificateReport({ id: id });

      // console.log(res);
      this.titleInfo = res.content;
      if (this.titleInfo.warranty) {
        this.titleInfo.warranty = JSON.parse(this.titleInfo.warranty);
      }
      console.log(res.content);
      this.getImgList(res.content.taskId, id);
      // 基本信息
      this.getInfo(res.content.taskId);
      // 报告
      this.getReportInfo(id);
      // 减值项
      this.getImpairmentInfo(res.content.taskId);
    },
    // 获取基本评估数据
    async getInfo(id) {
      const { data: res } = await reportInfo({ taskId: id });
      // console.log(res);
      this.msgInfo = res.content;
    },
    // 获取图片
    async getImgList(id, certId) {
      const { data: res } = await getVehicleImages_({
        taskId: id,
        certId: certId,
      });
      console.log(res);
      console.log("=============");
      res.content.forEach((item) => {
        item.imgList.forEach((items) => {
          this.imgList.push(items);
        });
      });
      this.list = res.content;
      this.$store.commit("setImg", res.content);
    },
    // 获取报告
    async getReportInfo(id) {
      const { data: res } = await certificationGetTechAppraisal({ id });
      this.tips = res.content.pop();
      res.content.forEach((item) => {
        // if (item.name == "骨架检查项目") {
        //   item.fullScore = item.fullScore.toFixed(2);
        //   item.score = item.score.toFixed(2);
        // }
      });
      this.reportInfo = res.content;
      console.log(this.reportInfo, "=====");
      this.$store.commit("setReport", res.content);
    },
    async getImpairmentInfo(id) {
      const { data: res } = await getImpairment({ taskId: id });
      if (res.content) {
        this.impairment = res.content;
        this.$store.commit("setImpairment", res.content);
        this.impairmentStatus = true;
      }
      console.log(res);
    },
    // 轮播图事件
    onChange(index) {
      this.current = index;
    },
    // 查看基本信息页面
    jumpInfo() {
      this.$router.push({
        path: `/reportInfo`,
        query: {
          id: this.titleInfo.taskId,
        },
      });
    },
    // 查看详细报告
    jumpReport(id) {
      // console.log(id);
      if (id !== "无") {
        this.$router.push({
          path: "/reportMessage",
          query: { id: id },
        });
      } else {
        this.$router.push("/reportMessage");
      }

      //
    },
    // 动态计算宽度
    _loopImg() {
      // let tabsWidth = 164;
      // let width = this.imgList.length * tabsWidth;
      // this.$refs.imgList[0].style.minwidth = `${width}px`;
    },
    // lookAll() {
    //   this.$router.push("/swiper");
    // },
    lookAlls(id, key) {
      let data = { id, key: key + 1 };
      this.$store.commit("setImgKey", data);
      this.$router.push("/swiper");
    },
    jumpImpairment() {
      this.$router.push("/impairment");
    },
    jumpEav() {
      console.log(this.titleInfo);
      return;
      this.$router.push({
        path: "/eva",
        query: {
          certId: this.titleInfo.evaPersonCertId,
        },
      });
    },
    // 点击图片
    onImageClick(list, index) {
      ImagePreview({
        images: list,
        startPosition: index,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #f8f8f8;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-bottom: 20px;
}
.img {
  width: 100vw;
  height: 211px;
  background: #ffdfdf;
}
.img > .van-image {
  width: 100vw;
  height: 100%;
}
.custom-indicator {
  position: absolute;
  width: 42px;
  height: 16px;
  right: 15px;
  bottom: 15px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.title {
  width: 92%;
  height: 55px;
  background-image: url("../../assets/report/title.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 10px;
}
.title-n {
  display: flex;
  font-size: 16px;
  color: #ffffff;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
}
.reset {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 17px 17px 17px 0px;
  font-size: 12px;
  // width: 58px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 6px;
  padding: 0 10px;
}
.title-n > li:nth-of-type(1) {
  display: flex;
}
.timer {
  font-size: 12px;
  color: #c9c9c9;
}
.timer-t {
  text-align: center;
}
.day {
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 80px;
  // padding-left: 10px;
  box-sizing: border-box;
  justify-content: center;
}
.day > img {
  width: 12px;
  height: 12px;
}
.m-tip {
  font-size: 13px;
}
.m-num {
  font-size: 20px;
}
.m-let {
  font-size: 13px;
}
//
.CertificationBody {
  width: 96%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(132, 197, 255, 0.6);
  border-radius: 4px;
  height: 135px;
  margin-top: 10px;
}
.CertificationBodyImage {
  width: 100%;
  height: 81px;
  background: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation/cert_background_20220616.png")
    no-repeat center center;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  flex-direction: column;
}
.CertificationBodyBottom {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
}
.bodyTitle {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.bodyImage {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
.bodyContent {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.carmsg {
  width: 96%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
}
.carName {
  min-height: 48px;
  box-sizing: border-box;
  padding: 12px;
  font-size: 17px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 20px;
}
.series {
  height: 34px;
  background: linear-gradient(180deg, #f3f7ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  display: flex;
  align-items: center;
}
.series > div {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-left: 12px;
}
.series > img {
  width: 13px;
  height: 13px;
  margin-left: 5px;
}
.changeNumber {
  display: flex;
  color: #666666;
  font-size: 13px;
  font-weight: 400;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  margin-top: 10px;
  justify-content: space-around;
}
.changeNumber > li {
  width: 108px;
  text-align: center;
}
.changeNumber > li > div:nth-of-type(1) {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  margin-bottom: 6px;
}
.changeNumber > li {
  width: 108px;
  text-align: center;
}
.changeNumber > .lines {
  width: 0px;
  height: 35px;
  border-left: 1px solid #e6e6e6;
}
.mechanism {
  width: 96%;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 10px;
  padding-bottom: 14px;
}
.m-title {
  height: 65px;
  background-image: url("../../assets/report/background.png");
  background-size: 100% 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  box-sizing: border-box;
  padding-top: 5px;
}
.m-title > div:nth-of-type(1) {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 0 12px;
  font-weight: 500;
}
.m-title > div:nth-of-type(2) {
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  height: 20px;
  line-height: 20px;
}
.m-content {
  /* display: flex; */
  margin-top: 10px;
  margin-left: 12px;
}
.m-content > li {
  display: flex;
  color: #333333;
  min-height: 30px;
  align-items: center;
  font-size: 16px;
}
.m-content > li > div:nth-of-type(1) {
  width: 74px;
  font-weight: 400;
}
.m-content > li > div:nth-of-type(2) {
  font-weight: 500;
  margin-left: 10px;
}
.m-tips {
  display: flex;
  justify-content: space-between;
}
.m-before {
  font-size: 16px;
  font-weight: 600;
  text-indent: 12px;
  margin-top: 12px;
}
.m-be-c {
  box-sizing: border-box;
  padding: 12px;
  font-size: 14px;
  line-height: 18px;
}
.m-be-c > span {
  font-weight: bold;
}
.m-be-tip {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-top: 10px;
  padding-bottom: 10px;
}
.m-be-tip > li {
  min-height: 20px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 0 10px;
}
.showMechanism {
  text-align: center;
  font-size: 14px;
  color: #999999;
  margin-top: 12px;
}
.showMechanism > span {
  margin-right: 4px;
}
.appraiser {
  width: 96%;
  height: 50px;
  background-image: url("../../assets/report/people.png");
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px 0 15px;
  margin-top: 10px;
}
.appraiserTitle {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.logo {
  display: flex;
  align-items: center;
}

.logo > .van-image {
  width: 26px;
  height: 26px;
  // border-radius: 50%;
  margin-right: 10px;
  // border: 1px solid #5ea7ff;
}
/deep/ .logo > .van-image > img {
  border-radius: 50%;
  // margin-right: 10px;
  border: 1px solid #5ea7ff;
}
.a-name {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}
.a-title-img {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 6px;
  align-items: center;
}
.a-title {
  display: flex;
  align-items: center;
}
.a-title > img {
  width: 13px;
  height: 11px;
  margin-right: 4px;
  // margin-top: 2px;
}
.a-link {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  text-align: center;
  margin-top: -4px;
}
.car-name {
  background: linear-gradient(180deg, #e7f0ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  /* padding: 0; */
  height: 77px;
  width: 96%;
  margin: 0 auto;
  margin-top: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.appInfo {
  width: 96%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 20px;
}
.app-list {
  box-sizing: border-box;
  padding: 0 15px 15px 15px;
}
.app-item {
  display: flex;
  font-size: 13px;
  color: #666666;
  align-items: center;
  height: 30px;
}
.app-item > div:nth-of-type(1) {
  width: 95px;
  margin-right: 4px;
}
.app-item > div:nth-of-type(2) {
  font-size: 14px;
  color: #333333;
}
.app-time > div:nth-of-type(1) {
  width: 180px;
}
.app-tip {
  font-size: 14px;
  line-height: 20px;
  /* margin-top: 10px; */
}
.lookMsg {
  width: 230px;
  height: 36px;
  background: #f4f8ff;
  line-height: 36px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
}
.showTimeTitle {
  font-size: 19px;
  text-align: center;
  padding-top: 12px;
  line-height: 30px;
}
.showTimeTip {
  width: 95%;
  margin: 0 auto;
  font-size: 12px;
  word-break: break-all;
  word-wrap: break-word;
  margin-top: 5px;
  line-height: 18px;
}
.showSeries {
  width: 95%;
  margin: 0 auto;
}

.carImgTitle {
  width: 95%;
  margin: 0 auto;
  height: 48px;
  background: linear-gradient(180deg, #e7f0ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  line-height: 48px;
  text-indent: 12px;
  margin-top: 10px;
  font-size: 19px;
}
.showSeries {
  margin-top: 10px;
}
.showSeriesT {
  font-size: 12px;
  font-weight: 500;
  color: #333333;
  margin: 8px 0;
}
.showSeriesTe {
  font-size: 10px;
  line-height: 12px;
}
/* .showSeriesTd {
  margin-top: 5px;
} */
.showSeriesD {
  margin: 5px 0;
  font-size: 10px;
}
.showSeriesD > span:nth-of-type(1) {
  font-weight: 500;
}
.cag-c {
  background: #ffffff;
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
}
.carimg-nav {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
  color: #333333;
  font-size: 17px;
}
.ca-more {
  font-size: 14px;
  color: #666666;
}
.carI-list {
  height: 105px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
}
.carI-list::-webkit-scrollbar {
  display: none;
}
.carI-list > li {
  width: 142px;
  height: 106px;
  margin-left: 10px;
}
.carI-list > li:nth-of-type(1) {
  margin-left: 12px;
}
.carI-list > li:last-child {
  margin-right: 12px;
}
.carI-list > li > .van-image {
  width: 142px;
  height: 106px;
}
/deep/ .carI-list > li > .van-image > img {
  border-radius: 3px;
}
.car-report {
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
}
.c-p {
  width: 100%;
}
.c-p-n {
  /* width: 100%; */
  background-image: url("../../assets/report/number.png");
  background-size: 100% 100%;
}
.c-p-n > li > div:nth-of-type(1) {
  color: #ffffff;
  margin-bottom: 10px;
}
.c-p-n > .lines {
  border-left: 1px solid #505050;
}
.report-list {
  box-sizing: border-box;
  padding: 0 12px;
  /* margin-top: 13px; */
}
.report-list-item:nth-of-type(1) {
  font-size: 16px;
  height: 50px;
}
.report-list-item:nth-of-type(1) > .report-bug {
  display: block;
}
.report-list-item > div:nth-of-type(1) {
  font-size: 16px;
}
.report-list-item {
  display: flex;
  min-height: 32px;
  justify-content: space-between;
  align-items: center;
}
.report-name {
  width: 110px;
  line-height: 20px;
}
.report-number,
.report-bug {
  width: 75px;
  font-size: 14px;
}
.report-level {
  width: 40px;
  font-size: 14px;
}
.report-bug {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 10px;
}
.report-bug > div {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  color: #ffffff;
}
.report-bug > .error {
  background: #f9702b;
}
.report-bug > .success {
  background: #5edb7b;
}
.look-report {
  margin-top: 10px;
}
.impairmentItem {
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
}
.im-nav {
  box-sizing: border-box;
  padding: 0 12px;
}

.im-item {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  border-top: 1px solid #cdcdcd;
  font-size: 15px;
}
.im-items {
  border: none;
}

.im-title {
  background: #f6f6f6;
  box-sizing: border-box;
  padding: 0 12px;
}
.im-top {
  border-top: 1px solid #cdcdcd;
}
.im-mark {
  padding: 0 12px;
  color: #666666;
  justify-content: space-between;
}
.im-mark > div:nth-of-type(1) {
  width: 130px;
}
.im-img {
  border-top: 1px solid #cdcdcd;
  display: flex;
  box-sizing: border-box;
  padding: 0 12px;
  height: 90px;
  align-items: center;
}
.im-img-item {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
.im-img-item > .van-image {
  width: 100%;
  height: 100%;
}
/deep/ .im-img-item > .van-image > img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.im-msg {
  line-height: 15px;
  min-height: 41px;
}
.specialTip {
  width: 95%;
  margin: 0 auto;
}
.sp-tips {
  box-sizing: border-box;
  padding: 12px;
  background: #ffffff;
  min-height: 40px;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}
// 其他认证资料
.certificationData {
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
}
.certificationDataFlex {
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
  padding: 0 12px;
}
.solid {
  border-bottom: 1px solid #f5f5f5;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 16px;
}
.certificationDataTitle {
  font-size: 17px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  margin-top: 6px;
}
.certificationDataOl {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
.certificationDataOlNull {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.certificationDataOl > img {
  width: 66px;
  height: 66px;
  margin: 0 17px 17px 0;
  border-radius: 6px;
}
/deep/ .van-popup {
  border-radius: 5px;
}
/deep/ .dayDia > .van-popup {
  width: 250px;
  height: 120px;
}
/deep/ .dayDia > .van-popup__close-icon--top-right {
  top: 0px !important;
  right: 0;
}
/deep/ .numDia > .van-popup {
  width: 250px;
  height: 100px;
}
/deep/ .serDia > .van-popup {
  width: 250px;
  height: 260px;
}
.Ntips {
  font-size: 16px;
  margin-top: 14px;
  text-align: center;
}
.app-item-img {
  display: flex;
  align-items: center;
}
.app-item-img img {
  width: 14px;
  height: 14px;
  margin-left: 6px;
}

.newList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  height: 180px;
}

.newList > li {
  width: 48%;
  height: 80px;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.newList > li img {
  width: 13px;
  height: 13px;
  margin-left: 3px;
  margin-top: -1px;
}

.newList > li > div:nth-of-type(1) {
  font-size: 18px;
  font-family: DINPro-Bold, DINPro;
  font-weight: bold;
  color: #333333;
}

.newList > li > div:nth-of-type(2) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #818181;
  margin-top: 10px;
}

.Usage {
  width: 96%;
  min-height: 124px;
  border-radius: 5px;
  background: #ffffff;
  margin: 10px auto;
  box-sizing: border-box;
  padding: 15px 12px;
}

.u-t {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.u-li {
  margin-top: 20px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.u-li > span:nth-of-type(2) {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 20px;
}

.report-names {
  height: 44px;
  display: flex;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  justify-content: space-between;
  align-items: center;
}

.report-bug {
  margin: 0;
}

.report-cs {
  height: 38px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  box-sizing: border-box;
  padding: 0 10px;
}
.sub-d {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff3b3b;
  margin-top: 13px;
}
.style_text {
  margin-top: 16px;
  margin-bottom: 16px;
}
.style_solid {
  border-bottom: 1px solid #ebebeb;
}
.report-text {
  font-family: PingFangSC, PingFang SC;
  font-size: 14px;
  color: #ff3b3b;
  margin-right: 3px;
}
.wrapper_box {
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
  margin-top: 10px;
  padding: 12px;
  box-sizing: border-box;
}
.wrapper_box > .title_1 {
  font-family: PingFangSC, PingFang SC;
  font-size: 16px;
  color: #333333;
  margin-bottom: 12px;
}
.wrapper_box > .text {
  font-family: PingFangSC, PingFang SC;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
}
.wrapper_box_view {
  width: 100%;
  background: #f8f8f8;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px;
}
.wrapper_box_view > .flex {
  display: flex;
  align-items: center;

  width: 100%;
  height: 22px;
}
.wrapper_box_view .title_1 {
  width: 100px;
  font-family: PingFangSC, PingFang SC;
  font-size: 15px;
  color: #333333;
}
.wrapper_box_view .text_1 {
  font-family: PingFangSC, PingFang SC;
  font-size: 14px;
  color: #333333;
}
.wuz {
//   width: 321px;
  height: 150px;
  font-family: PingFangSC, PingFang SC;
  background: #f8f8f8;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  padding: 20px 0;
  font-weight: 400;
  font-size: 15px;
  color: #666666;
  margin-top: 10px;
}
.wuz img {
  width: 76px;
  height: 78px;
  margin-bottom: 10px;
}
</style>
